<template>
    <footer>
        <div class="footer-container">
            <div class="footer-item">
                <AtmLogo />
                <div class="connect-wrapper">
                    <ConnectItem v-for="item in items" :key="item.id" :item="item" class="connect-item" />
                </div>
                <div class="discover-wrap">
                    <div class="discover-text">
                        <p>Discover us on:</p>
                    </div>
                    <div class="discover-links">

                        <div class="discover-btn">
                            <a href="https://coinmarketcap.com/community/profile/ATM_rank/" target="_blank">
                                <img src="images/homePage/coinmartketcap.svg" alt="coinmartketcap">
                            </a>
                        </div>

                        <div class="dextools-btn">
                            <a href="www.dextools.io/app/en/bnb/pair-explorer/0x09e61856a0f4d63e26ea07c1ed8f65d06b61d2eb?t=1733737362452"
                                target="_blank">
                                <img :src=imageSource alt="DEXTools">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="version">Version 2.0.0</div>
            </div>

            <div class="footer-info">
                <div class="dapp-button-wrapper">
                    <DappButton v-for="(footerButton, index) in localizedDataList" :key="index"
                        :button="footerButton" />
                </div>


                <div class="email-wrap">
                    <div class="emal-text">Email:</div>
                    <a href="mailto:autonomoustrustmomentum@gmail.com">autonomoustrustmomentum@gmail.com</a>
                </div>
                <div class="info">
                    <p> © 2020 - 2024 Autonomous Trust Momentum All Rights Reserved</p>
                </div>

                <div v-show="mobile" class="connect-wrapper">
                    <ConnectItem v-for="item in items" :key="item.id" :item="item" class="connect-item mobile" />
                </div>
                <div v-show="mobile" class="discover-wrap">
                    <div class="discover-text">
                        <p>Discover us on:</p>
                    </div>

                    <div class="discover-links">
                        <div class="discover-btn">
                            <a href="https://coinmarketcap.com/community/profile/ATM_rank/" target="_blank">
                                <img src="images/homePage/coinmartketcap.svg" alt="coinmartketcap">
                            </a>
                        </div>
                        <div class="dextools-btn">
                            <a href="www.dextools.io/app/en/bnb/pair-explorer/0x09e61856a0f4d63e26ea07c1ed8f65d06b61d2eb?t=1733737362452"
                                target="_blank">
                                <img :src=imageSource alt="DEXTools">
                            </a>
                        </div>
                    </div>

                </div>
                <div v-show="mobile" class="version">Version 2.0.0</div>
            </div>
        </div>
    </footer>
</template>

<script>


import AtmLogo from '@/components/common/atmLogo.vue'
import DappButton from '@/components/common/dappButton.vue'
import ConnectItem from '@/components/common/connectItem.vue';
import useDarkMode from '@/darkMode';
export default {
    name: 'Footer',

    data() {
        return {
            // version:AppJson.version
            mobile: null,
            buttons: [
                { key: 'downloadAndroid', image: 'images/app/google.svg', href: 'https://play.google.com/store/apps/details?id=network.atm.atmconnect&hl=en_GB&gl=US' },
                { key: 'downloadIOS', image: 'images/app/apple.svg', href: 'https://apps.apple.com/gb/app/atm-connect/id6463245714 ' }
            ],
            items: [
                { id: 1, imageUrl: "images/homePage/twitter.svg", text: "Twitter", link: 'https://twitter.com/ATMrank' },
                { id: 2, imageUrl: "images/homePage/telegram.svg", text: "Telegram", link: 'https://t.me/atm_luca' },
                { id: 3, imageUrl: "images/homePage/discord.svg", text: "Discord", link: 'https://discord.gg/bwAtDM7Mp2' },
                { id: 4, imageUrl: "images/homePage/reddit.svg", text: "Reddit", link: 'https://www.reddit.com/r/atmrank/' },
                { id: 5, imageUrl: "images/homePage/tiktok.svg", text: "TikTok", link: 'https://www.tiktok.com/@atm_rank?is_from_webapp=1&sender_device=pc' },
                { id: 6, imageUrl: "images/homePage/youtube.svg", text: "YouTube", link: 'https://www.youtube.com/@atmrank6968' },
            ]
        }
    },
    components: {

        AtmLogo,
        DappButton,
        ConnectItem,
    },

    setup() {
        const { isDark } = useDarkMode();
        return {
            isDark,
        };
    },

    computed: {
        localizedDataList() {
            return this.buttons.map(item => ({
                ...item,
                label: this.$t(item.key),
            }));
        },
        imageSource() {
            return this.isDark
                ? 'images/homePage/DEXTools-Light.png'
                : 'images/homePage/DEXTools-Dark.png';
        }
    },
    methods: {
        checkScreen() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth <= 750) {
                this.mobile = true;
                return;
            }
            this.mobileNav = false;
            this.mobile = false;
            return;
        },
    },
    created() {
        window.addEventListener('resize', this.checkScreen);
        this.checkScreen();
    },
}
</script>
<style>
@import '@/assets/style/main.scss';
</style>