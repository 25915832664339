import { createRouter, createWebHashHistory } from 'vue-router'
import HomePage from '../views/homePage.vue'
import TechnologyPage from '../views/technologyPage.vue'
import CommunityPage from '../views/communityPage.vue'
import ExplorerPage from '../views/explorerPage.vue'
import PartnersPage from '../views/partnersPage.vue'
import NewsPage from '../views/newsPage.vue'
import HelpPage from '../views/helpPage.vue'
import EcosystemPage from '../views/ecosystemPage.vue'
import GovernancePage from '../views/governancePage.vue'
import Dashboard from '../views/dapp/index.vue'
import GamingRulesPage from '../views/gamingPage.vue'
import { getAccount } from '@wagmi/core';
import GameManger from '../views/agf/index.vue'

import GameDetailPage from '../views/agf/gameDetailPage'
import { useBlockChainStore } from '@/store/blockchain'

import { loadLayoutMiddleware } from '@/router/middleware/loadLayoutMiddleware'
const routes = [

  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    meta: { needAuth: false, layout: "AppLayoutDefault" },

    component: HomePage,
  },
  {
    path: "/technology",
    name: "technology",
    meta: { needAuth: false, layout: "AppLayoutDefault" },

    component: TechnologyPage,
  },
  {
    path: "/community",
    name: "community",
    meta: { needAuth: false, layout: "AppLayoutDefault" },

    component: CommunityPage,
  },

  {
    path: "/explorer",
    name: "explorer",
    component: ExplorerPage,
    meta: { needAuth: false, layout: "AppLayoutDefault", noScroll: true },
    redirect: '/explorer/overview',
    children: [

      {
        path: 'search',
        name: 'SearchInfo',
        component: () => import('../components/explorerPage/searchInfo.vue'),

      },


      {
        path: 'overview',
        component: () => import('../components/explorerPage/overview.vue')
      },
      {
        path: 'galaxy',
        component: () => import('../components/explorerPage/galaxy.vue')
      },
      {
        path: 'rank',
        component: () => import('../components/explorerPage/rank.vue')
      },
      {
        path: 'consensusConnection',
        component: () => import('../components/explorerPage/consensusConnection.vue')
      },

      {
        path: 'consensusConnectionInfo',
        component: () => import('../components/explorerPage/consensusConnectionInfo.vue'),
        props: true
      },

      {
        path: 'prNode',
        component: () => import('../components/explorerPage/prNode.vue')
      },
      {
        path: 'prNodeInfo',
        component: () => import('../components/explorerPage/prNodeInfo.vue'),
        props: true
      },
      {
        path: 'stakeTransaction',
        component: () => import('../components/explorerPage/stakeTransaction.vue')
      },
      {
        path: 'stakeTransactionInfo',
        component: () => import('../components/explorerPage/stakeTransactionInfo.vue'),
        props: true
      },
      {
        path: 'contractInformation',
        component: () => import('../components/explorerPage/contractInformation.vue')
      },


    ]
  },


  {
    path: "/partners",
    name: "partners",
    meta: { needAuth: false, layout: "AppLayoutDefault" },

    component: PartnersPage,
  },
  {
    path: '/notice/:id?',
    name: 'news',
    component: NewsPage,
    meta: { needAuth: false, layout: "AppLayoutDefault" },
    props: route => ({ id: route.params.id })
  },
  {
    path: "/help",
    name: "help",
    meta: { needAuth: false, layout: "AppLayoutDefault" },

    component: HelpPage,
  },
  {
    path: "/ecosystem",
    name: "ecosystem",
    meta: { needAuth: false, layout: "AppLayoutDefault" },

    component: EcosystemPage,
  },
  {
    path: "/governance",
    name: "governance",
    meta: { needAuth: false, layout: "AppLayoutDefault" },
    component: GovernancePage,
  },
  {
    path: "/dapp",
    name: "dapp",
    meta: { needAuth: false, layout: "UserLayout" },
    component: Dashboard,
  },
  {
    path: "/gamePage",
    name: "gameRulesPage",
    meta: { needAuth: false, layout: 'AppLayoutDefault' },
    component: GamingRulesPage,
  },

  // {
  //   path: "/chat",
  //   name: "chat",
  //   meta: { needAuth: false  , layout: "AppLayoutDefault" },

  //   component: Chat,
  // },

  {
    path: '/game',
    name: "gameManager",
    meta: { needAuth: false, layout: "AppLayoutDefault" },
    component: GameManger,
    props: route => ({ id: route.params.id })

  },
  {
    path: '/gameDetails/:gameId', // Ensure the dynamic segment is declared here
    name: 'gameDetail',
    meta: { needAuth: false, layout: 'AppLayoutDefault' },
    component: GameDetailPage,
    props: true // Automatically maps route params to props
  },

  // {
  //   path: "/gamePage",
  //   name: "gamePage",
  //   meta: { needAuth: false },
  //   component: GamePage,
  //   props: (route) => ({ gameId: route.query.gameId , layout: "AppLayoutDefault" }),
  // },

  // {
  //   path: "/fullPage",
  //   name: "fullPage",

  //   meta: { needAuth: false  , layout: "GameLayout" },

  //   component: FullGamePage,
  //   props: (route) => ({ gameId: route.query.gameId }),
  // },

  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;

    } if (to.meta.noScroll) {
      return false;
    }
    return { left: 0, top: 0 };

  }
})

router.beforeEach((to, from, next) => {
  const blockChainStore = useBlockChainStore();
  const isLogin = blockChainStore.isLoggedIn();
  // get the open page and redirect to that page only
  loadLayoutMiddleware(to)
  if (isLogin) {
    next();
  } else {
    // check meta data auth is need
    if (to.meta.needAuth) {
      next({ name: "home" });
    } else {
      next();
    }
  }
});

export default router;
