 <template>
  <swiper
    class="swiper"
    :modules="modules"
    :slides-per-view="1"
    :slides-per-group="1"
    :centered-slides="true"
    :navigation="false"
    :pagination="{ clickable: true }"
    :autoplay="{ delay: 3000, disableOnInteraction: false }"
    :loop="normalizedSlides.length > 1" 
    ref="swiper"
    :speed="1000"
    effect="slide"
  >
    <swiper-slide v-for="slide in normalizedSlides" :key="slide.id">
      <div class="slide-img">
        <img lazy="loading" :src="slide.coverImg" alt="Slide Cover Image" />
      </div>
      <div class="slide-text-wrapper">
        <h3 class="slide-title">{{ slide.title }}</h3>
        <div class="slide-desc">
          <p v-html="slide.content"></p>
        </div>
        <router-link :to="{ name: 'news', params: { id: slide.id } }" class="slide-btn">
          <p>{{ $t('readMore') }}</p>
        </router-link>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default defineComponent({
  name: 'NewsSlider',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
  
    const normalizedSlides = computed(() =>
      props.slides.length < 2 ? [...props.slides, ...props.slides] : props.slides
    );

    return {
      modules: [Pagination, Navigation, Autoplay],
      normalizedSlides,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '../../assets/style/homePageStyle/newsSlider.scss';
</style>

